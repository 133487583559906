<template>
  <b-modal
    ref="modal-approved-by-ceo"
    size="md"
    title="Approve Request Recruitment"
    :no-close-on-backdrop="true"
    @hide="closeModalApprove"
  >
    <template #modal-title>
      <div class="d-flex justify-content-start align-items-center text-white">
        <tabler-icon
          icon="UserPlusIcon"
          size="20"
          class="mr-1"
        />
        <h4 class="m-0 p-0 text-white">
          Approve Request Recruitment
        </h4>
      </div>
    </template>
    <b-container class="p-1">
      <validation-observer ref="formRequestPersonnel">
        <b-row class="mb-1">
          <b-col>
            <validation-provider
              name="salary"
              rules="required|validate-amount|money-required"
            >
              <b-form-group label="Minimum Salary (S/)">
                <money
                  v-model="recruitment.min_amount"
                  v-bind="vMoney"
                  class="form-control input-form"
                  :disabled="currentUser.user_id !== 1"
                />
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col>
            <validation-provider
              name="salary"
              rules="required|validate-amount|money-required"
            >
              <b-form-group label="Maximum Salary (S/)">
                <money
                  v-model="recruitment.max_amount"
                  v-bind="vMoney"
                  class="form-control input-form"
                  :disabled="currentUser.user_id !== 1"
                />
              </b-form-group>
            </validation-provider>
          </b-col>

        </b-row>

      </validation-observer>

      <b-card
        title="DETAILS"
        :style="isDarkSkin ? 'background: #2e3439': ''"
      >

        <b-row>

          <b-col cols="4">
            <h6>ROL</h6>
            <b-badge variant="info">
              {{ recruitment.rol_name }}
            </b-badge>
          </b-col>
          <b-col cols="4">
            <h6>DATE REQUEST</h6>
            <p>{{ recruitment.date_request }}</p>
          </b-col>
          <b-col cols="4">
            <h6>START DATE</h6>
            <p>{{ recruitment.date_start }}</p>
          </b-col>
          <b-col cols="4">
            <h6>EMPLOYEES REQUIRED</h6>

            <h5>
              <b-badge
                variant="primary"
              >
                {{ recruitment.new_employee_quantity }}
              </b-badge>
            </h5>
          </b-col>

          <b-col cols="4">
            <h6>SHIFT</h6>
            <p>{{ recruitment.shift }}</p>
          </b-col>
          <b-col cols="4">
            <h6>LEVEL OF ENGLISH </h6>
            <p>{{ levelLanguage.text }}</p>
          </b-col>

          <b-col
            cols="12"
            class="mb-1"
          >
            <h6>REASON</h6>
            <b-form-textarea
              id="reason"
              v-model="recruitment.reason"
              rows="5"
              disabled
            />
          </b-col>

          <b-col
            cols="12"
            class="mb-1"
          >
            <h6>DETAILS</h6>
            <b-form-textarea
              id="reason"
              v-model="recruitment.details"
              rows="5"
              disabled
            />
          </b-col>

          <b-col
            cols="12"
            class="mb-1"
          >
            <h6>COMMENTARY</h6>
            <b-form-textarea
              id="reason"
              v-model="recruitment.commentary"
              rows="5"
              disabled
            />
          </b-col>
        </b-row>

        <b-card-text>
          {{ 'Created by: ' +recruitment.requester+', from '+ recruitment.module_name }}
        </b-card-text>

      </b-card>

    </b-container>
    <template #modal-footer>
      <div class="w-100 d-flex justify-content-end align-items-center">

        <b-button
          variant="danger"
          pill
          class="mr-1"
          @click="shippingRequest(4)"
        >

          <feather-icon
            icon="XCircleIcon"
          />

          Disapprove
        </b-button>

        <b-button
          variant="success"
          pill
          @click="shippingRequest(3)"
        >

          <feather-icon
            icon="CheckCircleIcon"
          />
          Approve
        </b-button>
      </div>
    </template>
  </b-modal>
</template>
<script>
import { mapGetters } from 'vuex';
import { VMoney } from 'v-money';
import Service from '@/views/commons/components/recruitment-process/services/recruiment.service';

export default {
  name: 'ModalApprovedByCeo',
  directives: { money: VMoney },
  props: {
    recruitment: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLanguages: [
        { id: 1, text: 'A1' },
        { id: 2, text: 'A2' },
        { id: 3, text: 'B1' },
        { id: 4, text: 'B2' },
        { id: 5, text: 'C1' },
        { id: 6, text: 'C2' },
      ],
      salary: null,
      date_request: null,
      date_start: null,
      new_employee_quantity: null,
      commentary: '',
      reason: '',
      shift: '',
      details: '',
      rol_name: '',
      requester: '',
      module_name: '',
      vMoney: {
        decimal: '.',
        thousand: ',',
        prefix: 'S/ ',
        precision: 2,
        min: 100.0,
        max: 150.0,
        maxlength: 12,
      },
      vmoneyValidate: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),

    levelLanguage() {
      return this.optionsLanguages.find((item) => item.id === this.recruitment.level_of_english);
    },
  },
  watch: {
    salary(newSalary) {
      // Verifica si el nuevo salario supera los 100
      if (newSalary > this.recruitment.min_amount && newSalary < this.recruitment.max_amount) {
        this.vmoneyValidate = true; // Activa la clase para el borde verde
      } else {
        this.vmoneyValidate = false; // Desactiva la clase para el borde verde
      }
    },
  },
  mounted() {
    this.toggleModal('modal-approved-by-ceo');
  },
  methods: {
    closeModalApprove() {
      this.$emit('closeModalApprove');
    },
    async shippingRequest(statusType) {
      if (statusType === 4 && this.salary) {
        this.showWarningSwal('Salary is not required to disapprove the request for new hires');
        return;
      }

      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;
        this.addPreloader();
        const params = {
          id: this.recruitment.id,
          min_amount: this.recruitment.min_amount,
          max_amount: this.recruitment.max_amount,
          status: statusType,
          user_id: this.currentUser.user_id,
          created_by: this.recruitment.created_by,
        };
        const data = await Service.approveRequestRecruitment(params);
        if (data.status === 200) {
          this.showSuccessSwal('Success', `Request Personal ${statusType === 3 ? 'Approved' : 'Disapproved'}`);
          this.$emit('closeModalApprove');
          this.$emit('refreshTable');
        }
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal();
      }
    },
  },
};
</script>
  <style>
  </style>
