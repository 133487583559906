export default [

  {
    key: 'module_name',
    label: 'Module',
    visible: true,
  },

  {
    key: 'requester',
    label: 'requester',
    visible: true,
  },

  {
    key: 'rol_name',
    label: 'Job Name',
    visible: true,
  },

  {
    key: 'new_employee_quantity',
    label: 'Contracted',
    visible: true,
    tdClass: 'text-center',
    thClass: 'text-center',
  },

  {
    key: 'salary',
    label: 'Salary range',
    visible: true,
    tdClass: 'text-center',
    thClass: 'text-center',
  },

  {
    key: 'shift',
    label: 'shift',
    visible: true,
    tdClass: 'text-center',
    thClass: 'text-center',
  },

  {
    key: 'status',
    label: 'status',
    visible: true,
    tdClass: 'text-center',
    thClass: 'text-center',
  },

  {
    key: 'date_start',
    label: 'start date',
    visible: true,
    tdClass: 'text-center',
    thClass: 'text-center',
  },

  {
    key: 'actions',
    label: 'actions',
    visible: true,
    tdClass: 'text-center',
    thClass: 'text-center',
  },
];
