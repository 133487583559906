<template>
  <div>

    <b-modal
      ref="modal_recruitment_process"
      v-model="onControl"
      size="sm"
      title="New Recruitment Process"
      modal-class="modal-primary"
      no-close-on-backdrop
      @hidden="close"
    >
      <validation-observer ref="observer">
        <b-row class="my-1">
          <b-col>
            <b-form-group>
              <validation-provider
                v-slot="{ errors }"
                rules="required|max:50"
                name="title"
              >
                <b-form-input
                  v-model="newJobPortal"
                  placeholder="Write the new job portal"
                  type="text"
                  :state="errors.length > 0 ? false : null"
                /><small
                  v-if="errors[0]"
                  class="text-danger"
                >
                  {{ errors[0] }}
                </small></validation-provider>
            </b-form-group>
          </b-col>
          <b-col>
            <b-button
              variant="success"
              :disabled="!newJobPortal || newJobPortal.length === 0"
              @click="addJobPortal()"
            >
              Add new job portal
            </b-button>

          </b-col>
        </b-row>
      </validation-observer>
      <validation-observer ref="observer2">
        <b-row>
          <b-col>
            <b-form-group label="Job Portal">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="title"
              >
                <v-select
                  id="jobVacancies"
                  v-model="jobVacancies"
                  :options="vacancies"
                  label="name"
                  multiple
                  placeholder="select the job portal"
                  :style="errors[0] ? 'border: 1px solid red;':''"
                /><small
                  v-if="errors[0]"
                  class="text-danger"
                >
                  {{ errors[0] }}
                </small>
              </validation-provider></b-form-group>
          </b-col>

        </b-row>
        <b-row>
          <b-col v-if="jobVacancies.length != 0">
            <b-list-group flush>
              <b-list-group-item
                v-for="(item, index) in jobVacancies"
                :key="index"
              >
                {{ item.name }}
                <br>
                <validation-provider
                  v-slot="{ errors }"
                  rules="required"
                  name="title"
                >
                  <money
                    id="amountOfInvestment"
                    v-model="item.amount"
                    v-bind="vMoney"
                    class="form-control input-form"
                    :style="errors[0] ? 'border: 1px solid red;':''"
                  />
                  <small
                    v-if="errors[0]"
                    class="text-danger"
                  >
                    {{ errors[0] }}
                  </small>
                </validation-provider></b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-around align-items-center">
          <b-button
            variant="primary"
            @click="save()"
          >
            Save
          </b-button>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import { VMoney } from 'v-money';
import Services from '@/views/commons/components/recruitment-process/services/recruiment.service';

export default {
  components: {
  },
  directives: { money: VMoney },
  props: {
    recruitment: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      errorMessage: false,
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15, 25, 50],

      startPage: null,
      toPage: null,
      items: [],
      sortBy: 'created_at',
      sortDesc: true,
      onControl: false,

      jobVacancies: [],
      amountOfInvestment: null,
      vacancies: [],
      newJobPortal: null,

      amount: null,
      name: '',
      vMoney: {
        decimal: '.',
        thousand: ',',
        prefix: 'S/ ',
        precision: 2,
        min: 0.0,
      },
      vmoneyValidate: false,
    };
  },
  computed: {
  },
  watch: {
  },
  async created() {
    this.onControl = true;
  },

  async mounted() {
    this.errorMessage = true;
    await this.getJobVacancies();
    await this.getRecruitmentJobVacancies();
  },
  methods: {
    close() {
      this.$emit('close');
    },
    preventNegativeInput(event) {
      const invalidKeys = ['-', '+', '*', '/', '=', '!', '@', '#', '$', '%', '^', '&', '(', ')'];
      if (invalidKeys.includes(event.key)) {
        event.preventDefault();
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async refresh() {
      await this.searchPayments(this.currentPage);
    },
    async getJobVacancies() {
      try {
        const { data } = await Services.getJobVacancies();
        this.vacancies = data.map(vacancie => ({
          id: vacancie.id,
          name: vacancie.name.toUpperCase(),
          amount: null,
        }));
      } catch (error) {
        console.error(error, 'error');
      }
    },
    async addJobPortal() {
      try {
        const validation = await this.$refs.observer.validate();
        if (!validation) return;
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;
        const ctx = {
          newJobPortal: this.newJobPortal,
        };
        const response = await Services.addNewJobPortal(ctx);

        if (response.data.code === 200) {
          this.showSuccessSwal(null, 'Job Portal added successfully!');
          this.newJobPortal = null;
          this.getJobVacancies();
        }
        if (response.data.code === 422) {
          this.showErrorSwal(null, 'This Job Portal is already exist!');
          this.newJobPortal = null;
          this.getJobVacancies();
        }
      } catch (error) {
        console.log(error);
      }
    },
    async save() {
      const validation = await this.$refs.observer2.validate();
      if (!validation) return;
      const { id } = this.recruitment;
      const jobVacanciesJson = JSON.stringify(this.jobVacancies);
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;
        const params = {
          id,
          job_vacancies: jobVacanciesJson,
        };
        const result = await Services.addNewRecruitmentProcess(params);
        this.errorMessage = false;
        this.result = result;
        //   this.items = result.data;
        //   this.totalRows = result.total;
        //   this.currentPage = result.current_page;
        this.$emit('close');
      } catch (error) {
        console.error(error);
      }
    },
    async getRecruitmentJobVacancies() {
      const { id } = this.recruitment;
      try {
        const obj = {
          id,
        };
        const res = await Services.getRecruitmentJobVacanciesPivot(obj);
        if (res.status === 200) {
          this.jobVacancies = res.data.map(item => ({
            amount: item.amount,
            id: item.job_vacancy_id,
            name: item.name.toUpperCase(),
          }));
          return res.data;
        }
      } catch (error) {
        console.log(error);
      }
      return [];
    },

  },
};
</script>
<style scope>
</style>
