<template>
  <div
    style="position: relative"
    class="recruitment-component"
  >
    <filter-slot
      :filter="filter"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      class="filter-table"
      @reload="$refs['refTable'].refresh()"
    >

      <template #buttons>

        <div class="mx-2">
          <b-row class="d-flex align-items-center">
            <b-col>
              <b-button
                href="#"
                variant="outline-info"
                size="sm"
                @click="openRecruitmentStatus"
              >
                Status Legend
              </b-button>
            </b-col>
          </b-row>
        </div>

      </template>
      <template #table>
        <b-table
          slot="table"
          ref="refTable"
          no-provider-filtering
          :items="search"
          :fields="visibleFields"
          primary-key="id"
          table-class="text-nowrap"
          responsive="sm"
          show-empty
          sticky-header="70vh"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(requester)="data">
            <span>
              <feather-icon
                icon="UserIcon"
                size="15"
                style="color: #0090E7"
              />
              {{ data.item.requester }} {{ data.item.role }}
            </span>
          </template>
          <template #cell(new_employee_quantity)="data">
            <span>
              <feather-icon
                icon="UsersIcon"
                size="15"
                style="color: #0090E7"
              /> {{ data.item.count_started_working }} of  {{ data.item.new_employee_quantity }} employe(s)
            </span>
          </template>
          <template #cell(salary)="data">
            <span v-if="data.item.min_amount">
              S/.
              {{ data.item.min_amount | currency }} - S/.
              {{ data.item.max_amount | currency }}
            </span>
            <span v-else>
              -
            </span>
          </template>

          <template #cell(shift)="data">
            <span :class="(data.item.shift === 'morning') ? 'text-primary' : (data.item.shift === 'afternoon') ? 'text-success' : 'text-info'">
              {{ data.item.shift.toUpperCase() }}
            </span>
          </template>

          <template #cell(status)="data">
            <b-row class="d-flex justify-content-center">
              <b-button
                style="border: none; border-radius:50px !important"
                :style="{ background: getStateColor(data.item.id_status) }"
                @click="openRecruitmentStatus"
              >
                <span
                  style="-webkit-text-stroke: 1px; color: black"
                  :style="(data.item.id_status === 4 || data.item.id_status === 6) && !isDarkSkin? 'color: white': ''"
                >
                  {{ data.item.status.toUpperCase() }}
                </span>
                <!-- <b-badge
                  v-if="data.item.id_status === 2
                    || data.item.id_status === 4"
                  variant="danger"
                  pill
                >
                  <feather-icon icon="BellIcon" />
                  <span class="sr-only">unread messages</span>
                </b-badge> -->
              </b-button>
              <tabler-icon
                icon="ListIcon"
                size="20"
                class="text-info cursor-pointer"
                style="margin-top:7px;margin-left:5px"
                @click="openModalStatusTracking(data.item)"
              />
            </b-row>
          </template>

          <template #cell(date_start)="data">

            <feather-icon
              icon="CalendarIcon"
              size="15"
              style="color: #0090E7"
            />
            {{ data.item.date_start | myGlobal }}
          </template>

          <template
            #cell(actions)="data"
          >
            <b-button
              v-if="data.item.id_status === 3"
              variant="success"
              size="sm"
              @click="startProcess(data.item)"
            >
              Start recruitment
              <tabler-icon
                icon="CircleCheckIcon"
                size="15"
              />
            </b-button>
            <b-button-group v-if="data.item.id_status !== 3">
              <b-button
                variant="outline-primary"
                size="sm"
                @click="openModalRecruitment(data.item)"
              >
                Select job board
              </b-button>
              <b-button
                variant="outline-secondary"
                size="sm"
                @click="openModalApplicants(data.item)"
              >
                Add CV'S
                <b-badge
                  v-if="data.item.count_approved_by_chief>0"
                  variant="warning"
                  pill
                  style="margin-left:3px"
                >
                  {{ data.item.count_approved_by_chief }}
                </b-badge>
              </b-button>
              <b-button
                :id="'tooltip-'+data.item.id"
                style="padding:0.3em 0.5em"
                variant="outline-success"
                size="sm"
                @click="showTimeLine(data.item.id)"
              >
                <close-open-icon
                  :key="isDarkSkin"
                  :index="data.item.id"
                  :color="isDarkSkin?'#fff':'#000'"
                />
              </b-button>
              <b-tooltip
                :target="'tooltip-'+data.item.id"
                :show-delay="1000"
                :hide-delay="1000"
                title="Show timeline"
                triggers="hover"
              />
            </b-button-group>

          </template>
        </b-table> </template></filter-slot>
    <modal-recruitment-process
      v-if="openModalRecruitmentProcess"
      :recruitment="recruitmentData"
      @close="closeModalRecruitment"
    />
    <modal-approved-by-ceo
      v-if="showModalApprovedByCeo"
      :recruitment="recruitmentData"
      @closeModalApprove="closeModalApprove"
    />
    <modal-recruitment-applicants
      v-if="showModalApplicants"
      :recruitment="recruitmentData"
      @closeModalRecruimentApplicants="closeModalRecruimentApplicants"
      @refreshTable="refreshTable()"
    />

    <modal-recruitment-status
      v-if="openModalRecruitmentStatus"
      @hidden="openModalRecruitmentStatus = false"
    />

    <time-line
      :key="render"
      :timeline-items="timelineItems"
      :background="backgroundUrl"
      :imgs-src="imgsSrc"
      class="timeline"
      @getPostulantData="(data, name) => getPostulantData(data, name)"
      @closeGlobalTimeline="(global) => closeGlobalTimeline(global)"
    />
    <time-line-individual
      v-if="showPostulantTimeline"
      :timeline-items="postulantData"
      :welcome-status="7"
      :status-name-position="statusNamePosition"
      :status-date-position="statusDatePosition"
      :wrapper-cords="wrapperCords"
      :postulant-name="postulantName"
      :back-url="imgsSrc.back"
      :flag-position="flagPosition"
      :flag-url="imgsSrc.flagUrl"
      :img-url="imgsSrc"
      :stop-icons-position="stopIconsPosition"
      class="timeline-individual"
      @closeIndividualTimeline="(global) => closeIndividualTimeline(global)"
      @showStageFiles="(item)=>showStageFiles(item)"
    />
    <modal-stage-files
      v-if="showModalStageFiles"
      :files="stageFiles"
      :stage-name="stageName"
      :applicant-name="postulantName"
      @closeModalFilesApplicant="showModalStageFiles = false"
    />
    <modal-recruitment-status-tracking
      v-if="recruitmentStatusTrackingModal"
      :recruitment="recruitmentData"
      @close="recruitmentStatusTrackingModal = false"
    />
  </div>
</template>
<script>
/*
* No quitar este import porfavor
*/

import style from '@haruka31dev/timeline/timeline.css';
import {
  TimeLine,
  TimeLineIndividual,
} from '@haruka31dev/timeline/timeline.common';
import { mapGetters } from 'vuex';
import CloseOpenIcon from '@/views/commons/components/recruitment-process/components/CloseOpenIcon.vue';
import filtersDataCeo from '@/views/commons/components/recruitment-process/data/filters.recruiment.data';
import Service from '@/views/commons/components/recruitment-process/services/recruiment.service';
import FieldsCeo from '@/views/commons/components/recruitment-process/data/fieldsCeo.recruitment.data';
import ModalApprovedByCeo from '@/views/commons/components/recruitment-process/components/modals/ModalApprovedByCeo.vue';
import ModalRecruitmentProcess from '@/views/commons/components/recruitment-process/components/modals/ModalRecruitmentProcess.vue';
import ModalRecruitmentApplicants from '@/views/commons/components/recruitment-process/components/modals/ModalRecruimentAplicants.vue';
import ModalRecruitmentStatus from '@/views/commons/components/recruitment-process/components/modals/ModalStatusLegends.vue';
import ModalStageFiles from '@/views/commons/components/recruitment-process/components/modals/ModalStageFiles.vue';
import ModalRecruitmentStatusTracking from '@/views/commons/components/recruitment-process/components/modals/ModalRecruitmentStatusTracking.vue';
import helpdeskService from '@/views/commons/components/helpdesk/services/helpdesk.service';

export default {
  components: {
    ModalApprovedByCeo,
    ModalRecruitmentProcess,
    ModalRecruitmentApplicants,
    TimeLine,
    TimeLineIndividual,
    CloseOpenIcon,
    ModalRecruitmentStatus,
    ModalStageFiles,
    ModalRecruitmentStatusTracking,
  },
  data() {
    return {
      openModalRecruitmentProcess: false,
      openModalRecruitmentStatus: false,
      recruitmentStatusTrackingModal: false,
      recruitment_id: null,
      // GRILLA
      operation: 1,
      modal: false,
      holidaySelected: {},
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      sortBy: 'entry_date',
      sortDesc: true,
      fields: FieldsCeo,
      startPage: null,
      endPage: '',
      nextPage: '',
      toPage: null,
      isBusy: false,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Name of requester or job name...',
        model: '',
      },
      filter: filtersDataCeo,
      transactionId: null,
      newForm: {
        description: '',
        day: null,
        month: null,
        created_by: '',
        rpt: '1',
        type: 'Day Calendar',
      },
      showModalApprovedByCeo: false,
      showModalApplicants: false,
      recruitmentData: null,
      timelineItems: {},
      idRecruitment: null,
      postulantData: [],
      postulantName: '',
      showPostulantTimeline: false,
      showModalStageFiles: false,
      stageFiles: [],
      backgroundUrl: {
        url: `${process.env.BASE_URL}assets/images/time-line/patternpad-5.svg`,
      },
      imgsSrc: {
        abilities: `${process.env.BASE_URL}assets/images/time-line/abilities.png`,
        autonomy: `${process.env.BASE_URL}assets/images/time-line/autonomy.png`,
        functions: `${process.env.BASE_URL}assets/images/time-line/functions.png`,
        requirements: `${process.env.BASE_URL}assets/images/time-line/requirements.png`,
        portada: `${process.env.BASE_URL}assets/images/time-line/portada.png`,
        arrow: `${process.env.BASE_URL}assets/images/time-line/arrow.png`,
        linkedin: `${process.env.BASE_URL}assets/images/time-line/linkedin.png`,
        facebook: `${process.env.BASE_URL}assets/images/time-line/facebook.png`,
        indeed: `${process.env.BASE_URL}assets/images/time-line/indeed.png`,
        logo: `${process.env.BASE_URL}assets/images/time-line/amg.svg`,
        info: `${process.env.BASE_URL}assets/images/time-line/info.png`,
        back: `${process.env.BASE_URL}assets/images/time-line/back.png`,
        flagUrl: `${process.env.BASE_URL}assets/images/time-line/flag-animation.png`,
        continue: `${process.env.BASE_URL}assets/images/time-line/continue.svg`,
        stop: `${process.env.BASE_URL}assets/images/time-line/stop.svg`,
      },
      statusNamePosition: {
        x: 30,
        y: 410,
      },
      statusDatePosition: {
        x: 30,
        y: 380,
      },
      wrapperCords: {
        top: 30,
        left: 100,
      },
      render: 0,
      flagPosition: {
        x: 160,
        y: 420,
      },
      stopIconsPosition: {
        x: 140,
        y: 50,
      },
      stageName: '',

    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    visibleFields() {
      return this.fields;
    },
  },
  mounted() {
    this.getModules();
    this.filter[3].visible = true;
  },
  methods: {
    async showStageFiles(item) {
      try {
        const params = {
          applicant_status_id: item.applicant_status_id,
        };
        // this.postulantName = item.name;
        this.stageName = item.name;
        this.addPreloader();
        const data = await Service.getApplicantStageFiles(params);
        this.stageFiles = data.data.data;
        if (data.status === 200) {
          this.removePreloader();
          this.showModalStageFiles = true;
        }
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal();
      }
    },
    openModalStatusTracking(recruitment) {
      this.recruitmentStatusTrackingModal = true;
      this.recruitmentData = recruitment;
    },
    openRecruitmentStatus() {
      this.openModalRecruitmentStatus = true;
    },

    async startProcess(recruitment) {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;
        this.addPreloader();
        const obj = {
          id: recruitment.id,
          min_amount: recruitment.min_amount,
          max_amount: recruitment.max_amount,
          status: 5,
          user_id: this.currentUser.user_id,
        };
        const data = await Service.approveRequestRecruitment(obj);
        if (data.status === 200) {
          this.showSuccessSwal('Success', 'Recruitment process begins');
          this.refreshTable();
        }
        this.removePreloader();
      } catch (error) {
        console.log(error);
      }
    },
    async getPostulantData(id, name) {
      const params = {
        id_recruitment: this.idRecruitment,
        id_postulant: id,
      };
      const data = await Service.getPostulantData(params);
      this.postulantData = data.data;
      this.postulantName = name;
      this.showPostulantTimeline = true;
      const timeline = document.querySelector('.timeline');
      timeline.style.display = 'none';
      const filterTabe = document.querySelector('.filter-table');
      filterTabe.style.display = 'none!important';
    },

    closeIndividualTimeline(a) {
      this.showPostulantTimeline = false;
      const timeline = document.querySelector('.timeline');
      timeline.style.display = 'block';
    },

    openModalRecruitment(recruitment) {
      this.openModalRecruitmentProcess = true;
      this.recruitmentData = recruitment;
    },
    closeModalRecruitment() {
      this.openModalRecruitmentProcess = false;
    },

    refreshTable() {
      this.$refs.refTable.refresh();
    },

    async search(ctx) {
      try {
        const obj = {
          name_text: this.filterPrincipal.model,
          perpage: this.paginate.perPage,
          page: this.paginate.currentPage,
          sort_by: ctx.sortBy,
          sort: ctx.sortDesc === true ? 'DESC' : 'ASC',
          tab: this.$route.meta.tab,
          module_id: this.isTabChief ? this.moduleId : this.filter[3].model,
          status: this.filter[2].model,
          from: this.filter[0].model,
          to: this.filter[1].model,
          shift: this.filter[4].model,
        };

        const res = await Service.getRecruitmentProcess(obj);
        if (res.status === 200) {
          this.startPage = res.data.from;
          this.paginate.currentPage = res.data.current_page;
          this.paginate.perPage = res.data.per_page;
          this.nextPage = this.startPage + 1;
          this.endPage = res.data.last_page;
          this.totalRows = res.data.total;
          this.toPage = res.data.to;
          return res.data.data;
        }
      } catch (error) {
        console.log(error);
      }
      return [];
    },

    toTitleCase(inputString) {
      return inputString
        .toLowerCase()
        .replace(/^(.)|\s(.)/g, match => match.toUpperCase());
    },
    modalApprovedByCeo(recruitment) {
      this.showModalApprovedByCeo = true;
      this.recruitmentData = recruitment;
    },
    async rejectedByCeo(recruitment) {
      try {
        const { isConfirmed } = await this.showConfirmSwal();
        if (!isConfirmed) return;
        this.addPreloader();
        const params = {
          id: recruitment.id,
          salary: recruitment.maximum_salary,
          status: 4,
          user_id: this.currentUser.user_id,
        };
        const data = await Service.approveRequestRecruitment(params);
        if (data.status === 200) {
          this.showSuccessSwal('Success', 'Request Personnel Rejected');
        }
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        this.showErrorSwal();
      }
    },
    closeModalApprove() {
      this.showModalApprovedByCeo = false;
    },
    openModalApplicants(recruitment) {
      this.showModalApplicants = true;
      this.recruitmentData = recruitment;
    },
    closeModalRecruimentApplicants() {
      this.showModalApplicants = false;
    },
    async showTimeLine(id) {
      this.render += 1;
      this.idRecruitment = id;
      const params = {
        id_recruitment: id,
      };
      const data = await Service.getRecruitmentTimeline(params);
      this.timelineItems = JSON.parse(data.data[0].items);
      const recruitment = document.querySelector('.recruitment-component');
      const timeline = document.querySelector('.timeline');
      timeline.style.display = 'block';
      recruitment.style.minHeight = '50em';
      const filterTabe = document.querySelector('.filter-table');
      filterTabe.style.display = 'none';
    },
    closeGlobalTimeline(global) {
      const recruitment = document.querySelector('.recruitment-component');
      const timeline = document.querySelector('.timeline');
      timeline.style.display = 'none';
      recruitment.style.height = 'auto';
      recruitment.style.minHeight = 'auto';
      const filterTabe = document.querySelector('.filter-table');
      filterTabe.style.display = 'block';
    },
    getStateColor(state) {
      switch (state) {
        case 1: // CREATED BY CHIEF
          return this.isDarkSkin ? '#ffe88d !important' : '#FFD700 !important';
        case 2: // SENT TO CEO
          return this.isDarkSkin ? '#9be5e5 !important' : '#00CED1 !important';
        case 3: // approved by ceo
          return this.isDarkSkin ? '#ffcd85 !important' : '#FFA500 !important';
        case 4: // rejected by ceo
          return this.isDarkSkin ? '#ca87e9 !important' : '#9400D3 !important';
        case 5: // started by rrhh
          return this.isDarkSkin ? '#9fe592 !important' : '#32CD32 !important';
        case 6: // closed by rrhh
          return this.isDarkSkin ? '#ff9473 !important' : '#FF0000 !important';
        default:
          return '';
      }
    },
    async getModules() {
      const response = await helpdeskService.getModules();
      if (response.status === 200) {
        this.filter[3].options = [{ name: 'Select a module', id: null }];
        this.filter[3].options = [
          ...this.filter[3].options,
          ...response.data,
        ];
      }
    },
  },
};
</script>
<style scoped>
.timeline {
  display: none;
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  overflow: hidden;
  max-height: 100vh !important;
}
.recruitment-component {
  position: relative;
  height: auto;

  overflow: hidden;
}
.timeline-individual {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  overflow: auto;
  max-height: 100vh !important;
}
</style>
